import { ReactComponent as PositionCloseIcon } from 'assets/svg/trade/close.svg'
import { ReactComponent as PositionEditIcon } from 'assets/svg/trade/edit.svg'
import { ReactComponent as PositionEntryIcon } from 'assets/svg/trade/entryIcon.svg'
import { ReactComponent as PositionLiqIcon } from 'assets/svg/trade/liqIcon.svg'
import styled from 'styled-components/macro'

import { ReactComponent as WinIcon } from '../../assets/imgs/airdrop/win.svg'
import { ReactComponent as ArrowDownIcon } from '../../assets/imgs/arrowDown.svg'
import { ReactComponent as BackIcon } from '../../assets/imgs/back.svg'
import { ReactComponent as CloseIcon } from '../../assets/imgs/close.svg'
import { ReactComponent as CloseIcon2 } from '../../assets/imgs/confirmation/close.svg'
import { ReactComponent as CopyIcon } from '../../assets/imgs/copy.svg'
import { ReactComponent as DropDownIcon } from '../../assets/imgs/down.svg'
import { ReactComponent as ExchangeIcon } from '../../assets/imgs/exchange.svg'
import { ReactComponent as ChangeIcon } from '../../assets/imgs/orderList/change.svg'
import { ReactComponent as SettingIcon } from '../../assets/imgs/setting2.svg'
import { ReactComponent as StarIcon } from '../../assets/imgs/star.svg'
import { ReactComponent as TipsDarkIcon } from '../../assets/imgs/tips_dark.svg'
import { ReactComponent as TipsLightIcon } from '../../assets/imgs/tips_light.svg'
import { ReactComponent as ToIcon } from '../../assets/imgs/to.svg'
import { ReactComponent as PriceChangeIcon } from '../../assets/svg/trade/change.svg'

export const StyledDropDown = styled(DropDownIcon)<{ selected?: boolean }>`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledArrowDown = styled(ArrowDownIcon)`
  path {
    stroke: ${({ theme }) => theme.textTabActive};
  }
`

export const StyledExchange = styled(ExchangeIcon)`
  cursor: pointer;
  path {
    stroke: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledSetting = styled(SettingIcon)`
  path {
    fill: ${({ theme }) => theme.connectBtnBackground};
  }
`

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.closeIconBg};
  }
`

export const StyledCloseIcon2 = styled(CloseIcon2)`
  cursor: pointer;
`

export const StyledVoteCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

export const StyledCopyIcon = styled(CopyIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.iconBg};
  }
  &:hover {
    path {
      fill: ${({ theme }) => theme.iconHoverBg};
    }
  }
`

export const StyledToIcon = styled(ToIcon)`
  path {
    stroke: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledTipsDarkIcon = styled(TipsDarkIcon)`
  cursor: pointer;
  &:hover {
    circle {
      fill: rgba(0, 178, 255, 0.2);
    }
  }
`

export const StyledTipsLightIcon = styled(TipsLightIcon)`
  cursor: pointer;
  &:hover {
    circle {
      fill: rgba(0, 178, 255, 0.2);
    }
  }
`

export const StyledBackIcon = styled(BackIcon)`
  cursor: pointer;
  path {
    stroke: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledChangeIcon = styled(ChangeIcon)`
  cursor: pointer;
  path {
    stroke: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledStarIcon = styled(StarIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

export const StyledPriceChangeIcon = styled(PriceChangeIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

export const StyledPositionCloseIcon = styled(PositionCloseIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

export const StyledPositionEditIcon = styled(PositionEditIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

export const StyledPositionLiqIcon = styled(PositionLiqIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

export const StyledPositionEntryIcon = styled(PositionEntryIcon)`
  cursor: pointer;
  .path1 {
    fill: ${({ theme }) => theme.backgroundInteractive};
  }
  .path2 {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

export const StyledWinIcon = styled(WinIcon)`
  path {
    fill: ${({ theme }) => theme.sectionBorder};
  }
`
